import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BiImageAdd } from 'react-icons/bi';
import mime from 'mime-types';
import { S3, PutObjectCommand } from '@aws-sdk/client-s3';

const UploadImage = ({ setAddProductData }) => {
  const [images, setImages] = React.useState([]);
  const [progress, setProgress] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const bucketName = 'maroga';
  const region = 'us-east-1';
  const s3 = new S3({ region: region });

  const handleChange = (e) => {
    setImages([]);
    for (let i = 0; i < e.target.files.length; i++) {
      const newImage = e.target.files[i];
      newImage['id'] = uuidv4();
      setImages((prevState) => [...prevState, newImage]);
    }
    setProgress(0);
  };

  const handleUpload = async () => {
    try {
      setLoading(true);

      const uploadPromises = images.map(async (image) => {
        const params = {
          Bucket: bucketName,
          Key: `${image.name}`,
          Body: image,
          ContentType: mime.lookup(image.name) || 'application/octet-stream',
        };

        await s3.send(new PutObjectCommand(params));

        const downloadURL = `https://${bucketName}.s3.${region}.amazonaws.com/${image.name}`;

        setAddProductData((prevState) => ({
          ...prevState,
          selectedFile: [...prevState.selectedFile, downloadURL],
        }));
      });

      await Promise.all(uploadPromises);

      setProgress(100);
    } catch (error) {
      setError('Error uploading images. Please try again.');
      console.error('Error uploading images:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      <label>Upload Image</label>
      <div className="w-full mt-1">
        <label
          htmlFor="image"
          className="flex items-center justify-center px-4 py-2 border border-[#edd5da] rounded-md shadow-sm"
        >
          <BiImageAdd className="text-xl" />
          &nbsp;
          {images.length ? (
            <span className="text-sm text-gray-500 ml-2">{images.length} image selected</span>
          ) : (
            'Upload Image'
          )}
          <button
            type="button"
            onClick={loading || progress === 100 ? null : handleUpload}
            className={
              images.length
                ? 'ml-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-[#f50057] hover:bg-[#f50057] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#f50057]'
                : 'hidden'
            }
          >
            {loading ? 'Uploading...' : progress === 0 ? 'Upload' : progress === 100 ? 'Done' : progress}
          </button>
        </label>
        <input
          multiple
          onChange={handleChange}
          style={{ display: 'none' }}
          name="image"
          id="image"
          type="file"
        />
      </div>
    </>
  );
};

export default UploadImage;

